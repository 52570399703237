import { useEffect, useState } from 'react';

import { REVIEW_TEMPLATE_STATUSES } from '@learned/constants';

import { getTemplatesNew } from '~/services/reviewTemplates';

import type { IReviewTemplate } from '@learned/types';

export const useReviewTemplates = () => {
  const [reviewTemplates, setReviewTemplates] = useState<IReviewTemplate[]>([]);

  const fetchReviewTemplates = async () => {
    const response = await getTemplatesNew(
      {
        status: [REVIEW_TEMPLATE_STATUSES.PUBLISHED],
      },
      {},
      ['questions'],
      ['themeName', 'themeIcon', 'skillCategories'],
    );
    setReviewTemplates(response.data.reviewTemplates);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchReviewTemplates();
    }

    return () => void (mounted = false);
  }, []);

  return { reviewTemplates, setReviewTemplates };
};
